.card-padding-16 {
  padding: 16px !important;
  margin: 16px;
}

.padding-vertical-16 {
  padding: 16px 0 16px 0;
}

.padding-below-16 {
  padding: 0 0 16px 0;
}


.login-card-div {
  width: 400px;
}

.login-card {
  padding: 16px 32px 0px 32px !important;
}

.mat-mdc-card-title {
  padding: 0px 0px 32px 0px !important;
}

.app-buttonsize-150 {
  width: 150px;
}

.app-elementwidth-300 {
  width: 300px;
}


